import React, { Component } from 'react';
import ImgPrint from '../img/print.png';
import ImgPrintDetails from '../img/print_info.png';
import ImgSend from '../img/send.png';
import ImgExport from '../img/export.png';
import PropTypes from 'prop-types';
import CallApi from '../../../utils/CallApi';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import getPaymentName from '../../../utils/getPaymentName';
import priceFormatting from '../../../utils/priceFormatting';
import LadditionLoader from '../../../components/LadditionLoader';
import PenSvg from '../svg/PenSvg';
import CheckSvg from '../svg/CheckSvg';
import { connect } from 'react-redux';
import decode from 'jwt-decode';
import { CSSTransition } from 'react-transition-group';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import { I18n } from 'react-redux-i18n';

class CustomerNoteDetails extends Component {
	constructor(props) {
		super(props);
		const refreshToken = window.localStorage.getItem('refreshToken');
		this.state = {
			linkPDF: '',
			listPaymentIds: props.listPaymentIds,
			actionPdfSelected: 'details',
			oldPdfSelected: '',
			errorPdf: false,
			disablePopup: false,
			userMail: decode(refreshToken).username
				? decode(refreshToken).username
				: '',
			msgContent: '',
			subject: `${I18n.t('customerAccount.yourBill')}`,
			resSend: '',
			labelWithoutDetails: `${I18n.t('customerAccount.labelDetailsDefault')}`,
			saveLabel: false,
			updateLabel: false,
			filter: props.filter,
			creditDetails: props.creditDetails,
			paymentTypes: props.paymentTypes,
			language: props.language,
			loading: false,
			renderPdf: false,
			emptyList: false,
			nameFileToDisplay: '',
			formatA4: false,
			sendSeparateNote: false,
			selectedCompany: props.selectedCompany,
			customerFirstName: props.customerFirstName,
			customerLastName: props.customerLastName,
			customerEmail: props.customerEmail,
			companies: props.companies,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.listPaymentIds !== this.state.listPaymentIds ||
			nextProps.filter !== this.state.filter ||
			nextProps.creditDetails.timestamp !==
				this.state.creditDetails.timestamp ||
			nextProps.paymentTypes !== this.state.paymentTypes ||
			nextProps.emptyList !== this.state.emptyList ||
			nextProps.companies !== this.state.companies
		) {
			this.setState(
				{
					listPaymentIds: nextProps.listPaymentIds,
					creditDetails: nextProps.creditDetails,
					paymentTypes: nextProps.paymentTypes,
					linkPDF: '',
					emptyList: nextProps.emptyList,
					filter: nextProps.filter,
					companies: nextProps.companies,
				},
				() => {
					if (
						(!nextProps.emptyList &&
							nextProps.listPaymentIds.length &&
							(this.state.actionPdfSelected !== 'send' ||
								this.nextProps.filter !== 'credits')) ||
						this.state.listPaymentIds.length > 0
					) {
						this.getPDF();
					}
				}
			);
		}
		if (
			nextProps.customerLastName !== this.state.customerLastName ||
			nextProps.customerFirstName !== this.state.customerFirstName ||
			nextProps.customerEmail !== this.state.customerEmail ||
			nextProps.selectedCompany !== this.state.selectedCompany
		) {
			this.setState({
				customerFirstName: nextProps.customerFirstName,
				customerLastName: nextProps.customerLastName,
				customerEmail: nextProps.customerEmail,
				selectedCompany: nextProps.selectedCompany,
				linkPDF: '',
			});
			this.noteIsSelected();
		}
		if (nextProps.listPaymentIds !== this.state.listPaymentIds) {
			this.setState({
				actionPdfSelected:
					this.state.actionPdfSelected === 'send'
						? 'details'
						: this.state.actionPdfSelected,
				linkPDF: '',
				loading: false,
			});
			this.noteIsSelected();
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.listPaymentIds.length &&
			((prevState.saveLabel !== this.state.saveLabel && this.state.saveLabel) ||
				prevState.formatA4 !== this.state.formatA4)
		) {
			this.getPDF();
		}

		if (prevState.actionPdfSelected !== this.state.actionPdfSelected) {
			if (
				this.state.actionPdfSelected === 'details' ||
				this.state.actionPdfSelected === 'withoutDetails'
			) {
				this.getPDF();
			}

			if (this.state.actionPdfSelected === 'send') {
				const {
					customerFirstName,
					customerLastName,
					listPaymentIds,
					companies,
				} = this.state;
				const listCompaniesPayment = groupBy(listPaymentIds, 'userDb');
				const listTmp = [];
				Object.keys(listCompaniesPayment).map(userDb => {
					return companies.filter(company =>
						company.company_db_name === userDb.toString()
							? listTmp.push(company.company_name)
							: ''
					);
				});

				const defaultMessage = `${I18n.t('customerAccount.hello')} ${
					customerLastName ? `${customerLastName}` : ''
				}${customerFirstName ? ` ${customerFirstName}` : ''}${I18n.t(
					'customerAccount.defaultSendMail'
				)} ${listTmp.join(`${I18n.t('customerAccount.and')}`)}`;

				this.setState({
					msgContent: defaultMessage,
				});
			}
		}

		if (prevState.linkPDF !== this.state.linkPDF) {
			this.props.onUpdateLinkPdf(this.state.linkPDF);
		}

		if (
			prevState.listPaymentIds !== this.state.listPaymentIds &&
			this.state.listPaymentIds.length
		) {
			this.setState({
				resSend: '',
			});
			this.props.onUpdateGenerationPdf(false);
		}
	}

	componentDidMount() {
		this.noteIsSelected();
	}

	noteIsSelected = () => {
		let testCheck;
		const checkboxes = window.document.querySelectorAll("input[name='ticket']");
		const ListInputTmp = Array.prototype.slice.call(checkboxes);
		testCheck = ListInputTmp.some(input => input.checked === true);
		if (!testCheck) {
			this.setState({
				//	listPaymentIds: [],
				//emptyList: true,
				linkPDF: '',
			});
		}
	};

	getPDF = async () => {
		const [...listPaymentIds] = this.state.listPaymentIds;

		const {
			actionPdfSelected,
			labelWithoutDetails,
			customerFirstName,
			customerLastName,
			formatA4,
		} = this.state;
		const groupedPaymentsList = groupBy(listPaymentIds, 'customerId');

		const label = labelWithoutDetails;
		const userName = `${
			customerLastName ? customerLastName.replace(/[^A-Z0-9]/gi, '') : ''
		}${
			customerFirstName
				? '-' + customerFirstName.replace(/[^A-Z0-9]/gi, '')
				: ''
		}`;

		if (groupedPaymentsList && Object.keys(groupedPaymentsList).length) {
			try {
				const accessToken = await window.localStorage.getItem('accessToken');

				this.setState(
					{
						loading: true,
						linkPDF: '',
						renderPdf: false,
					},
					() => {}
				);
				this.props.onUpdateGenerationPdf(true);
				const urlPDF = await CallApi(
					'POST',
					`customer-account/notes/${actionPdfSelected}`,
					'',
					{ groupedPaymentsList, label, userName, formatA4 },
					`accessToken ${accessToken}`
				);
				const result = urlPDF[0][0];

				this.setState(
					{
						linkPDF: result ? result.url.toString() : [],
						nameFileToDisplay: result ? result.nameFile : '',
						errorPdf: result.url.length > 0 ? false : true,
						saveLabel: false,
					},
					() => {
						if (this.state.linkPDF.length > 0) {
							setTimeout(() => {
								this.setState({
									renderPdf: true,
									loading: false,
								});
							}, 500);
						}
					}
				);
			} catch (e) {
				this.props.addFlashMessage({
					type: 'danger',
					text: I18n.t('accountMigration.error'),
					persistent: false,
				});
			}
		}
	};

	submitForm = async e => {
		e.preventDefault();
		const {
			userMail,
			customerEmail,
			msgContent,
			subject,
			nameFileToDisplay,
			formatA4,
			listPaymentIds,
			sendSeparateNote,
			labelWithoutDetails,
			customerFirstName,
			customerLastName,
			oldPdfSelected,
		} = this.state;

		const accessToken = await window.localStorage.getItem('accessToken');
		const userName = `${
			customerLastName ? customerLastName.replace(/\s/g, '') : ''
		}${customerFirstName ? '-' + customerFirstName.replace(/\s/g, '') : ''}`;
		try {
			this.setState({
				loading: true,
			});

			const postForm = await CallApi(
				'POST',
				'customer-account/notes/send',
				'',
				{
					userMail,
					customerEmail,
					subject,
					msgContent,
					nameFileToDisplay,
					listPaymentIds,
					formatA4,
					sendSeparateNote,
					userName,
					oldPdfSelected,
					labelWithoutDetails,
				},
				`accessToken ${accessToken}`
			);
			if (postForm[0] && postForm[0].message === 'success') {
				setTimeout(() => {
					this.setState({
						resSend: I18n.t('customerAccount.ticketSendCorrectly'),
						loading: false,
					});
				}, 500);
			} else {
				this.setState({
					resSend: I18n.t('customerAccount.sendMailError'),
					loading: false,
				});
			}
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: I18n.t('accountMigration.error'),
				persistent: false,
			});
		}
	};

	handleActionPDF = (e, action) => {
		e.preventDefault();
		if (action !== this.state.actionPdfSelected) {
			this.setState({
				actionPdfSelected: action,
				resSend: '',
				saveLabel: false,
				oldPdfSelected: this.state.actionPdfSelected,
			});
		}
	};

	handleExport = e => {
		e.preventDefault();
		const link = window.document.createElement('a');
		link.target = '_BLANK';
		link.href = this.state.linkPDF;
		link.setAttribute('download', 'ticket.pdf');
		window.document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	handlePopup = (e, bool) => {
		e.preventDefault();
		this.setState({
			disablePopup: bool,
		});
	};

	handleFormSend = e => {
		e.preventDefault();
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	resetEmail = e => {
		e.preventDefault();
		this.setState({
			resSend: '',
		});
	};

	handleLabelWithoutDetails = e => {
		e.preventDefault();
		this.setState({
			labelWithoutDetails: e.target.value,
			saveLabel: false,
		});
	};

	handleLabel = e => {
		e.preventDefault();
		this.setState({
			updateLabel: true,
		});
	};

	saveLabel = e => {
		e.preventDefault();
		this.setState({
			saveLabel: true,
			updateLabel: false,
		});
	};

	handleFormatPdf = e => {
		//e.preventDefault();
		this.setState({
			formatA4: !this.state.formatA4,
		});
	};

	handleOptionEmail = e => {
		this.setState({
			sendSeparateNote: !this.state.sendSeparateNote,
		});
	};

	render() {
		const {
			linkPDF,
			actionPdfSelected,
			listPaymentIds,
			userMail,
			customerEmail,
			msgContent,
			subject,
			resSend,
			filter,
			loading,
			labelWithoutDetails,
			paymentTypes,
			saveLabel,
			errorPdf,
			renderPdf,
			emptyList,
			language,
			updateLabel,
			oldPdfSelected,
			formatA4,
			sendSeparateNote,
		} = this.state;

		const renderEmailForm = userMailSender => {
			return (
				<CSSTransition
					classNames="displayForm"
					timeout={350}
					in={
						actionPdfSelected === 'send' && linkPDF && !resSend && !loading
							? true
							: false
					}>
					<form
						noValidate
						ref={this.form}
						onSubmit={this.submitForm}
						id="form-email">
						<label>
							<p>{`${I18n.t('customerAccount.mailSender')}* :`}</p>{' '}
							<input
								type="email"
								name="userMail"
								className="input"
								className={(userMailSender ? '' : 'empty', 'input')}
								value={userMail}
								onChange={this.handleFormSend}
							/>
						</label>
						<label>
							<p>{`${I18n.t('customerAccount.mailRecipient')}* :`}</p>{' '}
							<input
								type="email"
								name="customerEmail"
								value={customerEmail}
								className={customerEmail ? '' : 'empty input'}
								onChange={this.handleFormSend}
							/>
						</label>
						<label>
							<p>{`${I18n.t('customerAccount.mailSubject')}* :`}</p>{' '}
							<input
								type="text"
								name="subject"
								value={subject}
								className={subject ? '' : 'empty input'}
								onChange={this.handleFormSend}
							/>
						</label>

						<label>
							<p>{`${I18n.t('customerAccount.mailContent')}* :`}</p>{' '}
							<textarea
								name="msgContent"
								value={msgContent}
								className={msgContent ? '' : 'empty input'}
								onChange={this.handleFormSend}
							/>
						</label>
						<p className="attachment">
							{`${I18n.t('customerAccount.mailAttachement')} :`}{' '}
							{sendSeparateNote ? `${listPaymentIds.length}` : `1`} ticket(s){' '}
							{oldPdfSelected === 'details'
								? I18n.t('customerAccount.ticketDetailsMultiple')
								: I18n.t('customerAccount.ticketNoDetailsMultiple')}{' '}
							{formatA4 ? I18n.t('customerAccount.toPageFormat') : ''}
						</p>

						<button
							className={
								userMailSender && customerEmail && msgContent ? '' : 'disabled'
							}>
							{I18n.t('customerAccount.ticketSend')}
						</button>
					</form>
				</CSSTransition>
			);
		};

		const renderPreview = () => {
			if (emptyList) {
				return (
					<div className="center-p">
						<p>{I18n.t('customerAccount.noTicketSelected')}</p>
					</div>
				);
			} else {
				if (listPaymentIds.length) {
					if (
						(renderPdf &&
							linkPDF &&
							actionPdfSelected !== 'send' &&
							!errorPdf &&
							actionPdfSelected !== 'withoutDetails') ||
						(renderPdf &&
							linkPDF &&
							actionPdfSelected !== 'send' &&
							!errorPdf &&
							actionPdfSelected === 'withoutDetails')
					) {
						return (
							<React.Fragment>
								<embed src={linkPDF} type="application/pdf" />
							</React.Fragment>
						);
					}
					if (actionPdfSelected === 'send' && linkPDF && !resSend && !loading) {
						const userMailSender = userMail;
						return renderEmailForm(userMailSender);
					}
					if (resSend && linkPDF) {
						return (
							<CSSTransition
								classNames="resEmail"
								timeout={450}
								in={resSend.length ? true : false}>
								<div className="new-mail">
									<p>{resSend}</p>
									{/*  <button onClick={this.resetEmail}>
                    Envoyer un nouveau mail
                  </button> */}
								</div>
							</CSSTransition>
						);
					}
					if (loading) {
						return (
							<div className="loader">
								<LadditionLoader hidden={!this.state.loading} />
							</div>
						);
					}
					return (
						<div className="center-p">
							<p>{I18n.t('customerAccount.errorTicketGeneration')}</p>
						</div>
					);
				} else {
					return (
						<p className="center-p">
							{I18n.t('customerAccount.noTicketSelected')}
						</p>
					);
				}
			}
		};

		const renderCreditDetails = () => {
			const { creditDetails, emptyList } = this.state;
			console.log("DETAILS", creditDetails);
			if (
				creditDetails &&
				creditDetails.infos &&
				creditDetails.infos.length > 0 &&
				!this.state.emptyList
			) {
				const creditTs = moment
				.unix(creditDetails.timestamp)
				.format('DD/MM/YYYY - HH:mm');
				return (
					<div className="credit-details">
						<p>{creditTs}</p>
						<p className="title-credit">
							{I18n.t('customerAccount.oneCredit')}{' '}
							{paymentTypes &&
							paymentTypes.length > 1 &&
							creditDetails.id_payment_type
								? ` ${getPaymentName(
										paymentTypes,
										creditDetails.id_payment_type
								)}`
								: ''}
							<span>
								{priceFormatting(creditDetails.amount, language, 'EUR')}
							</span>
						</p>
						{creditDetails.infos.map((tickets, i) => {
									const ticketTs = moment
										.unix(tickets.timestamp)
										.format('DD/MM/YYYY - HH:mm');
									return (
										<div className="credit-note-paid" key={`credit-${i}`}>
											<p className="credit-user" key={`credit-p-${i}`}>
												{tickets && tickets.user_lastname
													? tickets.user_lastname
													: ''}{' '}
												{tickets && tickets.user_firstname
													? tickets.user_firstname
													: ''}
											</p>
											<p>
												{I18n.t('customerAccount.onTicket')} {ticketTs} (
												{tickets && tickets.payment_name
													? `${tickets.payment_name} `
													: ''}
												){' '}
												<span className="amount">
													{tickets && tickets.amount
														? priceFormatting(tickets.amount, language, 'EUR')
														: ''}
												</span>
											</p>
											<p>
												> {I18n.t('customerAccount.amountUsed')}
												<span className="amount">
													{tickets && tickets.amount_paid
														? priceFormatting(
																tickets.amount_paid,
																language,
																'EUR'
														)
														: ''}
												</span>
											</p>
										</div>
									);
							})
						}
					</div>
				);
			} else if (!creditDetails.infos) {
				return (
					<p className="center-p">
						{I18n.t('customerAccount.noPaymentLine')}
					</p>
				);
			} else if (!emptyList && !creditDetails) {
				return (
					<p className="center-p">
						{I18n.t('customerAccount.creditLoadDataError')}
					</p>
				);
			} else if (emptyList) {
				return (
					<p className="center-p">{I18n.t('customerAccount.noFoundCredit')}</p>
				);
			} else {
				return (
					<p className="center-p">
						{I18n.t('customerAccount.selectOneCredit')}
					</p>
				);
			}
		};

		const renderOptionPdf = filter => {
			return (
				<div className="action-pdf">
					<div className="format-pdf" onChange={this.handleFormatPdf}>
						<input
							className="checkbox"
							id="checkbox1"
							type="checkbox"
							checked={formatA4}
							readOnly={true}
						/>
						<label htmlFor="checkbox1" className="checkbox-label switch">
							<span className="on">{I18n.t('customerAccount.pageFormat')}</span>
							<span className="off">
								{I18n.t('customerAccount.pageFormat')}
							</span>
						</label>
					</div>

					{actionPdfSelected === 'withoutDetails' ? (
						<div className="label-withoutDetails">
							<p className={updateLabel ? 'form' : ''}>
								<span>{I18n.t('customerAccount.defaultLabel')}</span>{' '}
								{!!updateLabel && !saveLabel ? (
									<React.Fragment>
										<input
											value={labelWithoutDetails}
											onChange={this.handleLabelWithoutDetails}
										/>
										<span onClick={e => this.saveLabel(e)} className="btn">
											<CheckSvg />
										</span>
									</React.Fragment>
								) : (
									<React.Fragment>
										<span>{labelWithoutDetails}</span>
										<span
											className="btn pen"
											onClick={e => this.handleLabel(e)}>
											<PenSvg />
										</span>
									</React.Fragment>
								)}{' '}
							</p>
						</div>
					) : (
						''
					)}
				</div>
			);
		};

		const renderOptionMail = () => {
			return (
				<div className="action-pdf">
					<div className="email-option" onChange={this.handleOptionEmail}>
						<input
							className="checkbox"
							id="emailOption"
							type="checkbox"
							onChange={() => console.log('Checked')}
							checked={sendSeparateNote}
						/>
						<label htmlFor="emailOption" className="checkbox-label switch">
							<span className="on">
								{I18n.t('customerAccount.sendTicketPDF')}
							</span>
							<span className="off">
								{I18n.t('customerAccount.sendTicketPDF')}
							</span>
						</label>
					</div>
				</div>
			);
		};

		return (
			<React.Fragment>
				{filter !== 'credits' ? (
					<div className="bloc">
						<div
							className={`${
								filter !== 'credits' /* && !emptyList */
									? 'cta-enable'
									: 'cta-action-disabled'
							} cta-action `}
							onClick={e => this.handleActionPDF(e, 'details')}>
							<div
								className={actionPdfSelected === 'details' ? 'selected' : ''}>
								<div>
									<img src={ImgPrintDetails} alt="print info" />
								</div>
								<p> {I18n.t('customerAccount.ticketDetails')}</p>
							</div>
						</div>
						<div
							className={`${
								filter !== 'credits' /* && !emptyList */
									? 'cta-enable'
									: 'cta-action-disabled'
							} cta-action `}
							onClick={e => this.handleActionPDF(e, 'withoutDetails')}>
							<div
								className={
									actionPdfSelected === 'withoutDetails' ? 'selected' : ''
								}>
								<div>
									<img src={ImgPrint} alt="print" />
								</div>
								<p> {I18n.t('customerAccount.ticketNoDetails')}</p>
							</div>
						</div>

						<div
							className={`${
								listPaymentIds &&
								listPaymentIds.length > 0 &&
								linkPDF &&
								linkPDF.length
									? 'cta-enable'
									: 'cta-action-disabled'
							} cta-action`}
							onClick={e => this.handleActionPDF(e, 'send')}>
							<div className={actionPdfSelected === 'send' ? 'selected' : ''}>
								<div>
									<img src={ImgSend} />
								</div>
								<p> {I18n.t('customerAccount.ticketSend')}</p>
							</div>
						</div>
					</div>
				) : null}
				<div className="preview">
					{filter !== 'credits' &&
					!loading &&
					(actionPdfSelected === 'details' ||
						actionPdfSelected === 'withoutDetails')
						? renderOptionPdf(filter)
						: ''}
					{actionPdfSelected === 'send' && !emptyList && !loading && !resSend
						? renderOptionMail()
						: ''}
					{filter === 'credits' ? renderCreditDetails() : renderPreview()}
					<CSSTransition
						classNames="resEmail-enter"
						timeout={700}
						in={
							actionPdfSelected !== 'send' && linkPDF && listPaymentIds
								? true
								: false
						}>
						<div
							id="export"
							className={`${
								listPaymentIds &&
								listPaymentIds.length > 0 &&
								linkPDF &&
								linkPDF.length &&
								actionPdfSelected !== 'send'
									? 'cta-export'
									: 'cta-export-hide'
							}`}
							onClick={this.handleExport}>
							<div className="export-bloc">
								<img src={ImgExport} />
								<p>{I18n.t('customerAccount.export')}</p>
							</div>
						</div>
					</CSSTransition>
				</div>
			</React.Fragment>
		);
	}

	static defaultProps = {
		linkPDF: '',
		listPaymentIds: [],
		filter: '',
		creditDetails: {
			/*  amount: "0",
      id_payment_type: "",
      infos: [],
      timestamp: "0" */
		},
		paymentTypes: {},
		language: window.navigator.language,
		emptyList: false,
		selectedCompany: '',
		customerFirstName: '',
		customerLastName: '',
		customerEmail: '',
		companies: {
			amount: 0,
		},
	};
}

CustomerNoteDetails.propTypes = {
	linkPDF: PropTypes.string,
	listPaymentIds: PropTypes.array,
	filter: PropTypes.string,
	creditDetails: PropTypes.object,
	paymentTypes: PropTypes.object,
	language: PropTypes.array.isRequired,
	emptyList: PropTypes.bool.isRequired,
	selectedCompany: PropTypes.string,
	customerFirstName: PropTypes.string,
	customerLastName: PropTypes.string,
	customerEmail: PropTypes.string,
	companies: PropTypes.object.isRequired,
	onUpdateLinkPdf: PropTypes.func.isRequired,
	onUpdateGenerationPdf: PropTypes.func.isRequired,
};

export default connect(null, { addFlashMessage })(CustomerNoteDetails);
