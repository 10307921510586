import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-responsive-modal';
import CloseModal from '../svg/CloseModal';
import { I18n } from 'react-redux-i18n';
import CallApi from '../../../utils/CallApi';
import '../sass/customerAccount.css';
import CustomSwitch from '../../../components/Button/CustomSwitch';

function ModalSettings({
    openModal,
    onClose
}) {
    const [loading, setLoading] = useState(false);
    const [switchStates, setSwitchStates] = useState({});

    const settingsOptions = [
        { label: 'customerAccount.customerAccountManagement', dbKey: 'customer_account' },
        { label: 'customerAccount.deactivationOnBalanceOverrun', dbKey: 'ca_auto_disable' },
        { label: 'customerAccount.preventPaying', dbKey: 'ca_no_up_amount' },
        { label: 'customerAccount.preventNegativeBalance', dbKey: 'ca_no_negative' },
        { label: 'customerAccount.preventPaidTickets', dbKey: 'ca_no_us_ticket' },
        { label: 'customerAccount.preventCountingCustomers', dbKey: 'ca_auto_disabled' },
    ];

    const getUserOptions = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const keys = settingsOptions.map(option => option.dbKey);
            if (!keys.length) {
                console.error('No key found in settingsOptions.');
                return;
            }

            const response = await CallApi(
                'GET',
                'customer-account/getOptions',
                '',
                { keys },
                `accessToken ${accessToken}`
            );

            return response;
        } catch (error) {
            console.error('Error retrieving user options:', error);
        }
    }, []);

    const upsertUserOption = useCallback(async (key, value) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            await CallApi(
                'PUT',
                'customer-account/upsertOption',
                '',
                { key, value },
                `accessToken ${accessToken}`
            );
        } catch (error) {
            console.error('Error upserting user option:', error);
        }
    }, []);

    useEffect(() => {
        const loadUserOptions = async () => {
            setLoading(true);
            try {
                const response = await getUserOptions();
                if (response && response.length) {
                    const data = response[0];
                    const initialSwitchStates = settingsOptions.reduce((acc, option) => {
                        acc[option.label] = data[option.dbKey] === '1';
                        return acc;
                    }, {});
                    setSwitchStates(initialSwitchStates);
                }
            } catch (error) {
                console.error('Error loading user options:', error);
            } finally {
                setLoading(false);
            }
        };

        if (openModal) {
            loadUserOptions();
        }
    }, [openModal, getUserOptions]);

    const handleChange = async (event, option) => {
        const isChecked = event.target.checked;
        const newValue = isChecked ? "1" : "0";
        const key = option.dbKey;

        if (!key) {
            console.error("Key not found for the option :", option);
            return;
        }

        setSwitchStates(prevState => ({
            ...prevState,
            [option.label]: isChecked,
        }));
        try {
            await upsertUserOption(key, newValue);
        } catch(error) {
            console.error("Error during update :", error);
        }
    };

    return (
        <Modal
            classNames={{
                modal: 'export_history_modal',
                overlay: 'modal_export_history_overlay',
            }}
            open={openModal}
            showCloseIcon={false}
            closeOnOverlayClick={true}
            onClose={() => {
                if (loading) return false;
                onClose();
            }}>
            <div className="export_modal_content">
                <div
                    className="close_icon"
                    onClick={e => {
                        if (loading) return false;
                        onClose();
                    }}>
                    <CloseModal />
                </div>
                <h3>Paramètres</h3>
                <div className="settings_modal_main">
                    {settingsOptions.map(option => (
                        <div className="modal-row" key={option.label}>
                            <p className="modal-text">
                                {I18n.t(option.label)}
                            </p>
                            <CustomSwitch
                                checked={switchStates[option.label] || false}
                                onChange={(e) => handleChange(e, option)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Modal>
    );
}

export default ModalSettings;