import React, { useEffect, useState } from 'react';
import '../sass/dataExport.css';
import HeaderExport from './HeaderExport';
import { I18n } from 'react-redux-i18n';
import Drawer from '@material-ui/core/Drawer';
//import InputBase from '@material-ui/core/InputBase';
import DatePicker from '../../BarDistrib/components/DatePicker/DatePicker';
import ReactTable from 'react-table';
import Input from '../../../components/Input/Input';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import VatSettings from './NewVatSettings';

import { connect } from 'react-redux';
import CallApi from '../../../utils/CallApi';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';

import { vatExportLabels } from '../constant';
import moment from 'moment';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const orderTypes = [
	{ label: 'Sur place', type: 1 },
	{
		label: 'À emporter',
		type: 2,
	},
	{
		label: 'Livraison',
		type: 3,
	},
];

function ExportVat({ companies, user, addFlashMessage }) {
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const [selectedOrderTypeAway, setSelectedOrderTypeAway] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [dateBegin, setDateBegin] = useState(
		moment().subtract(7, 'days').toDate()
	);
	const [dateBeginLabel, setDateBeginLabel] = useState(
		moment().subtract(7, 'days').format('DD-MM-YYYY')
	);
	const [dateEnd, setDateEnd] = useState(moment().toDate());
	const [dateEndLabel, setDateEndLabel] = useState(
		moment().format('DD-MM-YYYY')
	);
	const [datas, setDatas] = useState([]);
	const [orderLabels, setOrderLabels] = useState('orderLines');
	const [userEmail, setUserEmail] = useState(user.email);
	const [dataLoading, setDataLoading] = useState(false);
	const [exportRoute, setExportRoute] = useState('exportVat/getExportDatas');
	const [openDrawer, setOpenDrawer] = useState(false);
	const [needToReload, setNeedToReload] = useState(false);
	const [isExportable, setIsExportable] = useState(false);
	const [exportFormat, setExportFormat] = useState('FEC');
	const [variables, setVariables] = useState({});
	const [vatKeys, setVatKeys] = useState({});

	useEffect(() => {
		getDataExport();
		getIsExportable();
	}, [
		dateBegin,
		dateEnd,
		exportRoute,
		selectedOrderTypeAway,
		selectedCompanies,
		needToReload,
		vatKeys,
	]);

	useEffect(() => {
		fetcher('customer-account/getOptions', res => setVariables(res), {
			keys: ['siret'],
		});
	}, []);

	const fetcher = async (route, callback, params = {}) => {
		try {
			const accesToken = window.localStorage.getItem('accessToken');
			const res = await CallApi(
				'GET',
				route,
				'',
				params,
				`accessToken ${accesToken}`
			);
			if (res && res[0] && callback) {
				return callback(res[0]);
			}
		} catch (error) {
			addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	const onDatePickerValidate = params => {
		if (
			moment(dateBegin).format('DD-MM-YYYY') !==
				moment(params.from).format('DD-MM-YYYY') ||
			moment(dateEnd).format('DD-MM-YYYY') !==
				moment(params.enteredTo).format('DD-MM-YYYY')
		) {
			setDateBeginLabel(params.fromLabel);
			setDateBegin(params.from);
			setDateEndLabel(params.toLabel);
			setDateEnd(params.enteredTo);
			setOpenDatePicker(false);
		}
	};

	const formateDate = (data, formatDisplay) => {
		return moment(data).format(formatDisplay);
	};

	const getDataExport = async () => {
		const accessToken = window.localStorage.getItem('accessToken');
		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;
		setDataLoading(true);
		try {
			const dataToExport = await CallApi(
				'GET',
				exportRoute,
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),
					idCompanies: tmpCompanies.map(c => c.id_company),
					limit: true,
				},
				`accessToken ${accessToken}`
			);

			if (dataToExport && dataToExport.length > 0 && dataToExport[0].success) {
				setDatas(dataToExport[0].datas);
				setDataLoading(false);
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	const getIsExportable = async () => {
		const accessToken = window.localStorage.getItem('accessToken');
		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;

		try {
			const res = await CallApi(
				'POST',
				'exportVat/isExportable',
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),
					idCompanies: tmpCompanies.map(c => c['id_company']),
				},
				`accessToken ${accessToken}`
			);

			if (res && res.length > 0 && res[0].success) {
				setIsExportable(res[0].result);
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	useEffect(() => {
		getSettings();
	}, [needToReload]);

	const getSettings = async (key, value) => {
		const accessToken = window.localStorage.getItem('accessToken');
		try {
			await CallApi(
				'GET',
				'exportVat/getVatKeys',
				'',
				{},
				`accessToken ${accessToken}`
			)
				.then(results => {
					if (results && results.length) {
						setVatKeys(results[0].result);
					}
				})
				.catch(err => {
					console.error(err);
					addFlashMessage({
						type: 'danger',
						text: 'Une erreur est survenue',
						persistent: false,
					});
					return err;
				});
		} catch (err) {
			console.log(err);
		}
	};

	const onChangeSettings = async (key, value) => {
		const accessToken = window.localStorage.getItem('accessToken');
		try {
			await CallApi(
				'POST',
				'exportVat/updateVatKeys',
				'',
				{
					vatKeys: { [key]: value },
				},
				`accessToken ${accessToken}`
			)
				.then(results => {
					if (results && results.length) {
						addFlashMessage({
							type: 'success',
							text: 'Vos paramètres ont été mis à jour',
							persistent: false,
						});
						setVatKeys(results[0].result);
					}
				})
				.catch(err => {
					console.error(err);
					addFlashMessage({
						type: 'danger',
						text: 'Une erreur est survenue',
						persistent: false,
					});
					return err;
				});
		} catch (err) {
			console.log(err);
		}
	};

	const exportData = async () => {
		if (isExportable) {
			if (exportFormat === 'FEC') convertAndDownloadTxt();
			else convertAndDownloadCsv();
		} else
			alert(
				'L’export n’est pas possible car tous les comptes comptables ne sont pas affectés'
			);
		return;
		const accessToken = window.localStorage.getItem('accessToken');

		const tmpCompanies =
			selectedCompanies && selectedCompanies.length > 0
				? selectedCompanies
				: companies;

		try {
			const exportCsv = await CallApi(
				'GET',
				'exportVat/exportVatCsv',
				'',
				{
					opening_date: formateDate(dateBegin, 'YYYY-MM-DD 00:00:00'),
					closing_date: formateDate(dateEnd, 'YYYY-MM-DD 23:59:59'),
					idCompanies: tmpCompanies.map(c => c['id_company']),
					email: userEmail,
					limit: false,
				},
				`accessToken ${accessToken}`
			);

			if (exportCsv && exportCsv.length > 0 && exportCsv[0].success) {
				addFlashMessage({
					type: 'success',
					text: I18n.t('lneExport.exportProcessing'),
					persistent: false,
				});
			}
		} catch (error) {
			console.log(error);
			addFlashMessage({
				type: 'error',
				text: "Une erreur s'est produite",
				persistent: true,
			});
		}
	};

	const onEmailChange = e => {
		e.preventDefault();
		setUserEmail(e.target.value);
	};

	const handleOpenDatePicker = () => {
		setOpenDatePicker(!openDatePicker);
	};

	const handleCompanyChanges = async c => {
		if (JSON.stringify(c) !== JSON.stringify(selectedCompanies)) {
			setSelectedCompanies(c);
		}
	};

	const handleRouteChanges = r => {
		if (r !== exportRoute) {
			setExportRoute(r);
		}
	};

	const handleCloseDatePicker = c => {
		setOpenDrawer(false);

		if (c) {
			setNeedToReload(!needToReload);
		}
	};

	const headers = [
		{
			label: `JournalCode`,
			value: 'journal',
		},
		{
			label: `JournalLib`,
			value: 'journallib',
		},
		{
			label: `EcritureNum`,
			value: 'ecritureNum',
		},
		{
			label: `EcritureDate`,
			value: 'date',
		},
		{
			label: `CompteNum`,
			value: 'account',
		},
		{
			label: `CompteLib`,
			value: 'lib',
		},
		{
			label: `CompAuxNum`,
			value: 'accountAux',
		},
		{
			label: `CompAuxLib`,
			value: 'accountAuxLib',
		},
		{
			label: `PieceRef`,
			value: 'numberCaisse',
		},
		{
			label: `PieceDate`,
			value: 'numberCaisseDate',
		},
		{
			label: `EcritureLib`,
			value: 'ecritureLib',
		},
		{
			label: `Debit`,
			value: 'debit',
		},
		{
			label: `Credit`,
			value: 'credit',
		},
		{
			label: `EcritureLet`,
			value: 'ecritureLet',
		},
		{
			label: `DateLet`,
			value: 'dateLet',
		},
		{
			label: `ValidDate`,
			value: 'validDate',
		},
		{
			label: `Montantdevise`,
			value: 'montantdevise',
		},
		{
			label: `idevise`,
			value: 'idevise',
		},
		/*{
			label: `Société`,
			value: 'society',
		},*/
		/*{
			label: `Etab`,
			value: 'etab',
		},*/
		/*{
			label: `Type de pièce`,
			value: 'pieceType',
		},*/
		/*{
			label: `Axe analytique`,
			value: 'axeAnal',
		},*/
		/*{
			label: `Section analytique`,
			value: 'sectionAnal',
		},*/
		/*{
			label: `Type`,
			value: 'type',
		},*/
		/*{
			label: `Montant`,
			value: 'amount',
		},*/
		/*{
			label: `Devise`,
			value: 'devise',
		},*/
		/*{
			label: `Montant en Devise`,
			value: 'amountD',
		},*/
		/*{
			label: `Echéance`,
			value: 'echeance',
		},*/
		/*{
			label: `Mode Règlement`,
			value: 'reglMode',
		},
		{
			label: `Tiers`,
			value: 'tiers',
		},
		{
			label: `Profil TVA`,
			value: 'profilVat',
		},
		{
			label: `Intraco`,
			value: 'intraco',
		},
		{
			label: `Nature`,
			value: 'nature',
		},
		{
			label: `Section Axe 1`,
			value: 'sectionAxe1',
		},
		{
			label: `Section Axe 2`,
			value: 'sectionAxe2',
		},
		{
			label: `Section Axe 3`,
			value: 'sectionAxe3',
		},*/
	];

	const convertData = (delimiter = ',') => {
		let headerLabels = headers.map(h => h.label);

		let content = '';

		content += headerLabels.join(delimiter) + '\r\n';

		const dataOrdered = datas.map(row => {
			let _row = {};
			for (let h of headers) {
				if (delimiter == ',') {
					_row[h.value] = row[h.value] ? '"' + row[h.value] + '"' : '';
				} else {
					_row[h.value] = row[h.value] ? row[h.value] : '';
				}
			}

			return _row;
		});

		dataOrdered.forEach(row => {
			content += Object.values(row).join(delimiter) + '\r\n';
		});

		return content;
	};

	const convertAndDownloadTxt = _ => {
		let siren = variables.siret
			? variables.siret.slice(0, -5).replace(' ', '')
			: 'SIREN';
		let filename = siren + 'FEC' + moment(dateEnd).format('YYYYMMDD') + '.txt';

		const link = document.createElement('a');
		const content = convertData('|');
		const file = new Blob([content], { type: 'text/plain' });
		link.href = URL.createObjectURL(file);
		link.download = filename;
		link.click();
		URL.revokeObjectURL(link.href);
	};

	const convertAndDownloadCsv = _ => {
		const DELIMITER = ',';
		let csvContent = 'data:text/csv;charset=utf-8,' + convertData(DELIMITER);

		var encodedUri = encodeURI(csvContent);
		var link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute(
			'download',
			`export-comptable-${dateBeginLabel}-${dateEndLabel}.csv`
		);
		document.body.appendChild(link); // Required for FF

		link.click(); // This will download the data file.
	};

	const columnsFirstLevel = vatExportLabels[orderLabels];

	const btnExportDisabled = datas && datas.length > 0 ? false : true;

	return (
		<div className="vat-export-main">
			<div className="export-header">
				<h3>
					Export écriture comptable{' '}
					<a
						href={
							'https://laddition.slite.page/p/_d4zEgJACwTsR4/Export-comptable'
						}
						target="blank"
						className="export-header-button-help">
						Besoin d'aide ?
					</a>
				</h3>
			</div>
			<div className="export-filters-container">
				<HeaderExport
					companies={companies}
					selectedCompanies={selectedCompanies}
					onDatePickerClick={handleOpenDatePicker}
					onCompaniesChange={c => handleCompanyChanges(c)}
					dateBegin={dateBegin}
					dateBeginLabel={dateBeginLabel}
					dateEnd={dateEnd}
					dateEndLabel={dateEndLabel}
				/>
				<div>
					<button
						className="export-settings-btn"
						onClick={() => {
							setOpenDrawer(true);
						}}>
						Réglages
					</button>
				</div>
			</div>
			<div className="export-visualisation-container">
				<span className="export-visualisation-title">
					{' '}
					Prévisualisation{' - '}
					Mode de visualisation :
					<select
						value={vatKeys['account_vat_mode'] || '2'}
						onChange={e =>
							onChangeSettings('account_vat_mode', e.target.value)
						}>
						<option value={'1'}>
							Fichier des écritures comptables (FEC) - Général
						</option>
						<option value={'2'}>
							Fichier des écritures comptables (FEC) - Détaillé
						</option>
					</select>
				</span>
				<ReactTable
					columns={columnsFirstLevel}
					showPagination={true}
					data={datas && datas.length > 0 ? datas : []}
					loadingText={I18n.t('fisc.table.loadingText')}
					noDataText={dataLoading ? '' : I18n.t('fisc.table.noDataText')}
					loading={dataLoading}
					resizable={true}
					sortable={false}
					className="visualisation-table"
				/>
				<DatePicker
					open={openDatePicker}
					anchorEl={anchorEl}
					onValidate={params => onDatePickerValidate(params)}
				/>
			</div>
			<div className="export-sender-container">
				{/* <Input
					label={I18n.t('lneExport.emailHint')}
					type="email"
					name="email"
					required="required"
					idInput="email-input"
					value={userEmail}
					onChange={onEmailChange}
				/> */}

				<Button
					disabled={btnExportDisabled}
					variant="contained"
					className={btnExportDisabled ? 'btn-export disabled' : 'btn-export'}
					onClick={() => {
						exportData();
					}}>
					Exporter
				</Button>
				<ToggleButtonGroup
					value={exportFormat}
					exclusive
					onChange={(e, value) => setExportFormat(value)}>
					<ToggleButton value="FEC">FEC</ToggleButton>
					<ToggleButton value="CSV">CSV</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<Drawer
				anchor="right"
				open={openDrawer}
				classes={{
					root: 'export-drawer',
					paper: 'export-drawer-paper',
				}}
				onClose={handleCloseDatePicker}>
				<div className="export-setting-container">
					<VatSettings onCloseDrawer={handleCloseDatePicker} />
				</div>
			</Drawer>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		companies: state.entities.informations.undefined.companies,
		user: state.auth.user,
	};
}
export default connect(mapStateToProps, { addFlashMessage })(ExportVat);
