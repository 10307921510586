import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayPicker, { DateUtils } from 'react-day-picker';
import ImgCalendar from '../img/calendar.svg';
import ImgRemove from '../img/delete.png';
import ImgReturn from '../img/return-red.svg';
import CallApi from '../../../utils/CallApi';
import 'react-dates/initialize';
import '../sass/customerAccountDetails.css';
import 'react-day-picker/lib/style.css';
import Clusterize from 'clusterize.js';
import CustomerNotesDetails from '../components/CustomerNotesDetails';
import CustomerNotesList from '../components/CustomerNotesList';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Link } from 'react-router';
import priceFormatting from '../../../utils/priceFormatting';
import { I18n } from 'react-redux-i18n';
import { deepClone } from '../../../utils/utils';
import { connect } from 'react-redux';
import TableLoader from '../../../common/tableloader/TableLoader';
import { addFlashMessage } from '../../../common/flashMessage/actions/flashMessages';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ImgTrash from '../img/trash.png';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { browserHistory } from 'react-router';

//@TODO comment gérer la langue automatiquement
import 'moment/locale/fr';
moment.locale('fr');
const language = window.navigator.languages;

class CustomerAccountDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: '',
			date: new Date(),
			accounts: props.accounts,
			customerLastName: '',
			customerFirstName: '',
			customerId: '',
			customerAmount: 0,
			accountDetails: {
				balance: 0,
				nbVisite: 0,
				info1: 0,
				info2: 0,
			},
			companies: {},
			customerInfos: {
				customerCompanyId: '',
				customerCompanyName: '',
				email: '',
				phone: '',
			},
			displayCalendar: false,
			paymentTypes: {},
			dataNotes: {},
			linkPDF: '',
			selectedCompany: '',
			from: undefined,
			to: undefined,
			editRange: {},
			creditDetails: {
				amount: '0',
				id_payment_type: '',
				infos: [],
				timestamp: '0',
			},
			locale: 'fr',
			emptyList: false,
			listPaymentIds: [],
			loading: true,
			generatePdf: false,
			modalOpen: false,
		};
	}

	async componentDidMount() {
		const getPeriodValueSessionStorageFrom = window.sessionStorage.getItem(
			'periodValueFrom'
		)
			? window.sessionStorage.getItem('periodValueFrom')
			: '';

		const getPeriodValueSessionStorageTo = window.sessionStorage.getItem(
			'periodValueTo'
		)
			? window.sessionStorage.getItem('periodValueTo')
			: '';

		if (
			(getPeriodValueSessionStorageFrom &&
				moment(getPeriodValueSessionStorageFrom)._isValid &&
				getPeriodValueSessionStorageFrom !== this.state.from) ||
			(getPeriodValueSessionStorageTo &&
				moment(getPeriodValueSessionStorageTo)._isValid &&
				getPeriodValueSessionStorageTo !== this.state.to)
		) {
			this.setState({
				from: new Date(getPeriodValueSessionStorageFrom),
				to: new Date(getPeriodValueSessionStorageTo),
			});
		}

		await this.getCustomerAccountDetails();
		await this.getNotesCustomerAccount('unpaid');

		if (this.state && !this.state.loading) {
			const clusterize = new Clusterize({
				scrollId: 'scrollArea',
				contentId: 'contentArea',
			});
		}
		this.checkEspaceClientGroup();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.to &&
			this.state.from &&
			this.state.displayCalendar &&
			this.state.date &&
			(prevState.to !== this.state.to || prevState.from !== this.state.from)
		) {
			this.displayCalendar();
		}
		if (this.state.from !== prevState.from || this.state.to !== prevState.to) {
			this.getNotesCustomerAccount();
			window.sessionStorage.setItem('periodValueFrom', this.state.from);
			window.sessionStorage.setItem('periodValueTo', this.state.to);
		}
		if (this.state.selectedCompany !== prevState.selectedCompany) {
			const filter = this.state.filter ? this.state.filter : 'unpaid';

			this.getNotesCustomerAccount(filter);
			this.setState({
				listPaymentIds: [],
			});

			const checkboxes = window.document.querySelectorAll(
				"input[type='checkbox']"
			);
			const listInputTmp = Array.prototype.slice.call(checkboxes);
			listInputTmp.map(input => (input.checked = false));
		}
		if (prevProps.auth.user !== this.props.auth.user) {
			this.checkEspaceClientGroup();
		}
	}

	checkEspaceClientGroup() {
		const user = this.props.auth.user;
		if (user && user.groups) {
			const hasEspaceClientGroup = user.groups.some(group => group.name === 'espace_client');
			this.setState({ hasEspaceClientGroup });
		}
	}

	/**
	 * get details of customer account
	 */
	getCustomerAccountDetails = async () => {
		const customerIdsList = window.location.pathname.split('/').pop();
		const customerIds = customerIdsList.split(',');
		const nameRestaurantUrl = window.location.search;
		const id_company = nameRestaurantUrl.length
			? nameRestaurantUrl.replace(/\?id=/g, '')
			: '';
		try {
			const accessToken = window.localStorage.getItem('accessToken');
			const customerAccountDetails = await CallApi(
				'POST',
				`customer-account/${customerIdsList}`,
				'',
				{ id_company, customerIds },
				`accessToken ${accessToken}`
			);
			const res = customerAccountDetails[0][0];
			this.setState({
				customerId: res.id,
				customerLastName: res.lastname,
				customerFirstName: res.firstname,
				accountDetails: {
					balance: res.account_details.balance,
					nbVisite: res.account_details.visit,
					info1: res.account_details.guest_average,
					info2: res.account_details.order_average,
				},
				companies: res.companies,
				customerInfos: {
					customerCompanyId: res.customer_infos.customer_company_id,
					customerCompanyName: res.customer_infos.customer_company_name,
					email: res.customer_infos.details_email,
					phone: res.customer_infos.details_phone,
				},
			});
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	onUpdateLinkPdf = val => {
		let { ...state } = this.state;
		state.linkPDF = val;
		state.generatePdf = false;
		this.setState(state);
	};

	onUpdatePaymentIds = val => {
		let { ...state } = this.state;
		state.listPaymentIds = val;
		this.setState(state);
	};

	onUpdateCreditDetails = details => {
		let { ...state } = this.state;
		state.creditDetails = details;
		this.setState(state);
	};

	onUpdateEmptyListBool = bool => {
		let { ...state } = this.state;

		state.emptyList = bool;
		this.setState(state);
	};

	onUpdateGenerationPdf = bool => {
		let { ...state } = this.state;
		state.generatePdf = bool;
		this.setState(state);
	};

	getAllNotes = async (filter, contactsIds, contactDb, tsStart, tsEnd) => {
		try {
			const accessToken = await window.localStorage.getItem('accessToken');
			const data = await CallApi(
				'POST',
				`customer-account/notes/${filter}`,
				'',
				{
					contactsIds,
					contactDb,
					tsStart,
					tsEnd,
				},
				`accessToken ${accessToken}`
			);
			if (filter === 'credits' && this.state.paymentTypes.length === 0) {
				this.getListPaymentType(contactDb);
			}
			const details = data[0];

			for (let d in details[contactsIds]) {
				details[contactsIds][d] = {
					...details[contactsIds][d],
					userDb: contactDb,
				};
			}
			const listeNotes = {
				details: details,
				userDb: contactDb,
			};
			return listeNotes;
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	/**
	 * get all customer notes
	 * @param {*} filter: paid (by default) || unpaid || credit
	 */
	getNotesCustomerAccount = async strFilter => {
		const filter = strFilter ? strFilter : this.state.filter;
		let tsStart = '';
		let tsEnd = '';
		if (this.state.from && this.state.to) {
			const start = moment(this.state.from).format('YYYY-MM-DD 00:00:00');
			const end = moment(this.state.to).format('YYYY-MM-DD 23:59:59');

			tsStart = moment(start).unix();
			tsEnd = moment(end).unix();
		}
		const { companies, selectedCompany } = this.state;

		//const tmpdata = [];
		const isFiltredByCompany = selectedCompany && selectedCompany !== 'all';

		if (isFiltredByCompany) {
			const findCompany = companies.find(company => {
				return company.id === parseInt(selectedCompany);
			});
			const contactsIds = findCompany.id_customer;
			const contactDb = findCompany.company_db_name;
			this.setState({
				loading: true,
			});

			const notes = await this.getAllNotes(
				filter,
				contactsIds,
				contactDb,
				tsStart,
				tsEnd
			);

			this.setState({
				dataNotes: {
					[contactsIds]: notes,
				},
				filter: filter,
				emptyList: false,
				loading: false,
			});
		} else if (companies && companies.length) {
			let tmpNotesDetails = [];
			companies.map(async company => {
				const contactsIds = company.id_customer;
				const contactDb = company.company_db_name;
				const notes = await this.getAllNotes(
					filter,
					contactsIds,
					contactDb,
					tsStart,
					tsEnd
				);
				// notes -> false STOP
				const newDataNotes = deepClone(this.state.dataNotes);
				if (notes && notes.details && notes.details[contactsIds]) {
					notes.details[contactsIds].map(el => {
						tmpNotesDetails.push(el);
					});
					this.setState({
						dataNotes: Object.assign(newDataNotes, {
							[contactsIds]: {
								details: {
									[contactsIds]: tmpNotesDetails,
								},
								userDb: notes.userDb,
							},
						}),
						filter: filter,
						emptyList: false,
						loading: false,
					});

					tmpNotesDetails = [];
				}
			});
		}
	};

	handleFilter = (e, filter) => {
		e.preventDefault();
		if (filter !== this.state.filter) {
			this.getNotesCustomerAccount(filter);

			this.setState({
				listPaymentIds: [],
				generatePdf: false,
			});
			// if checkbox all, uncheck
			if (this.state.checkboxAll) {
				setTimeout(() => {
					this.setState({
						checkboxAll: false,
					});
				}, 500);
			}
		}
	};

	getListPaymentType = async userDb => {
		try {
			const accessToken = window.localStorage.getItem('accessToken');
			const listPayment = await CallApi(
				'POST',
				`customer-account/notes/paymentTypes`,
				'',
				{ userDb },
				`accessToken ${accessToken}`
			);
			this.setState({
				paymentTypes: listPayment[0],
			});
		} catch (e) {
			this.props.addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue',
				persistent: false,
			});
		}
	};

	deleteSelectedAccount = async (customerId) => {
		try {
            const accessToken = await window.localStorage.getItem('accessToken');
           	await CallApi(
                'POST',
                'customer-account/customer/removed',
                '',
                { customerId },
                `accessToken ${accessToken}`
            );
        } catch (error) {
            console.error('Error deleting account:', error);
        }
	}

	handleDayClick = day => {
		let { from, to } = this.state;
		const range = DateUtils.addDayToRange(day, this.state);
		this.setState(range);
	};

	displayCalendar = () => {
		this.setState({
			displayCalendar: !this.state.displayCalendar,
		});
	};

	handleCompany = e => {
		this.setState({
			selectedCompany: e.target.value === 'all' ? 'all' : e.target.value,
		});
	};

	handleResetClick = () => {
		this.setState({
			from: undefined,
			to: undefined,
		});
		window.sessionStorage.removeItem('periodValueFrom');
		window.sessionStorage.removeItem('periodValueTo');
	};

	handleOpenModal = () => {
		this.setState({ modalOpen: true });
	};

	handleCloseModal = () => {
		this.setState({
			modalOpen: false,
		});
	};

	handleDelete = async () => {
		const selectedId = this.state.customerId;
		try {
			if (!this.state.accountDetails.balance) {
				await this.deleteSelectedAccount(selectedId);
				browserHistory.push("/customer-account");
				this.props.addFlashMessage({
					type: 'success',
					text: "Le compte a été supprimé avec succès.",
					persistent: false,
				});
			}
        } catch(error) {
            console.error("Error during delete :", error);
			addFlashMessage({
				type: 'danger',
				text: 'Une erreur est survenue lors de la supression.',
				persistent: false,
			});
        }
	};

	render() {
		const {
			filter,
			customerFirstName,
			customerLastName,
			companies,
			accountDetails,
			selectedCompany,
			from,
			to,
		} = this.state;

		const modifiers = { start: from, end: to };

		const listCompany = () => {
			if (companies && companies.length) {
				if (companies.length === 1) {
					return <p>{companies[0].company_name}</p>;
				}
				return (
					<select value={selectedCompany} onChange={this.handleCompany}>
						<option value="all">
							{I18n.t('customerAccount.allCompanies')}
						</option>
						{companies.map((company, i) => {
							return (
								<option value={company.id} key={`${company.id}-${i}`}>
									{company.company_name}
								</option>
							);
						})}
					</select>
				);
			}
		};

		const capitalize = str => {
			if (typeof str !== 'string') return '';
			return str.charAt(0).toUpperCase() + str.slice(1);
		};
		return (
			<div id="customer-account-details">
				<main>
					<header className="customer-account-details-header">
						<div className="customer-account-actions">
							<h3 className="title-details">
								<Link to={'/customer-account'}>
									<img src={ImgReturn} className="img-return" alt="return" />
								</Link>
								{I18n.t('customerAccount.accountsDetails')}
							</h3>
							{!this.state.accountDetails.balance && this.props.isSuite &&
								<button
									className="btn-delete"
									type="button"
									onClick={this.handleOpenModal}
								>
									<p>Supprimer</p>
									<img src={ImgTrash} alt="Delete" className='trash'/>
								</button>
							}
						
						</div>
						<div className="select-company">
							<Link to={'/customer-account'} />
							{listCompany()}
						</div>
						<div className="info-client-header">
							<div className="info-client">
								<h4 className="name">
									{customerLastName ? capitalize(customerLastName) : ''}{' '}
									{customerFirstName ? capitalize(customerFirstName) : ''}
								</h4>
								<p>-</p>
								<h3 className="amount">
									{' '}
									{accountDetails && accountDetails.balance
										? priceFormatting(accountDetails.balance, language, 'EUR')
										: ''}
								</h3>
							</div>
							<div className="filter-bloc">
								<div className="filter-action">
									<div
										onClick={e => this.handleFilter(e, 'unpaid')}
										className={filter === 'unpaid' ? 'selected' : ''}>
										{I18n.t('customerAccount.notOnSold')}
									</div>
									<div
										className={filter === 'paid' ? 'selected' : ''}
										onClick={e => this.handleFilter(e, 'paid')}>
										<p>{I18n.t('customerAccount.sold')}</p>
									</div>
									<div
										className={filter === 'credits' ? 'selected' : ''}
										onClick={e => this.handleFilter(e, 'credits')}>
										{I18n.t('customerAccount.credits')}
									</div>
								</div>
								<div className="filter-agenda">
									<div
										onClick={
											/* from && to ? "" : */ e => this.displayCalendar()
										}>
										{from && to ? (
											<React.Fragment>
												<p>
													<img
														src={ImgRemove}
														onClick={this.handleResetClick}
														className="remove"
														alt="img remove"
													/>
													{from && to && (
														<span onClick={e => this.displayCalendar()}>
															{moment(from).isBefore(to)
																? moment(from).format('L')
																: moment(to).format('L')}{' '}
															au{' '}
															{moment(from).isBefore(to)
																? moment(to).format('L')
																: moment(from).format('L')}
														</span>
													)}
												</p>
											</React.Fragment>
										) : (
											<p>{I18n.t('customerAccount.selectPeriod')}</p>
										)}
										<img
											src={ImgCalendar}
											alt="calendar"
											className="img-calendar"
											onClick={e => this.displayCalendar()}
										/>
									</div>
								</div>
							</div>
						</div>
						{this.state.displayCalendar ? (
							<TransitionGroup>
								<CSSTransition
									classNames="list-transition"
									timeout={300}
									in={this.state.displayCalendar}>
									<div className="calendar">
										<img
											src={ImgRemove}
											alt="remove"
											onClick={this.displayCalendar}
											className="list-transition--overlay"
										/>
										<DayPicker
											locale="fr"
											localeUtils={MomentLocaleUtils}
											className="Selectable"
											numberOfMonths={1}
											selectedDays={[from, { from, to }]}
											modifiers={modifiers}
											onDayClick={this.handleDayClick}
										/>
										<p
											className="reset-calendar"
											onClick={this.handleResetClick}>
											{I18n.t('customerAccount.resetPeriod')}
										</p>
									</div>
								</CSSTransition>
							</TransitionGroup>
						) : (
							''
						)}
					</header>
					{this.state.loading ? (
						<TableLoader />
					) : (
						<div className="clusterize">
							<div id="scrollArea" className="clusterize-scroll">
								<div className="table">
									<CustomerNotesList
										filter={this.state.filter}
										dataNotes={this.state.dataNotes}
										onUpdateLinkPdf={this.onUpdateLinkPdf}
										onUpdatePaymentIds={this.onUpdatePaymentIds}
										onUpdateCreditDetails={this.onUpdateCreditDetails}
										paymentTypes={this.state.paymentTypes}
										language={language}
										onUpdateEmptyListBool={this.onUpdateEmptyListBool}
										emptyList={this.state.emptyList}
										selectedCompany={this.state.selectedCompany}
										generatePdf={this.state.generatePdf}
										onUpdateGenerationPdf={this.onUpdateGenerationPdf}
									/>
								</div>
							</div>
						</div>
					)}
				</main>
				<div className="pdf-bloc">
					<CustomerNotesDetails
						listPaymentIds={this.state.listPaymentIds}
						filter={this.state.filter}
						creditDetails={this.state.creditDetails}
						paymentTypes={this.state.paymentTypes}
						language={language}
						dataNotes={this.state.dataNotes}
						emptyList={this.state.emptyList}
						selectedCompany={this.state.selectedCompany}
						customerFirstName={this.state.customerFirstName}
						customerLastName={this.state.customerLastName}
						customerEmail={this.state.customerInfos.email}
						companies={this.state.companies}
						onUpdateLinkPdf={this.onUpdateLinkPdf}
						onUpdateGenerationPdf={this.onUpdateGenerationPdf}
					/>
				</div>
				<Dialog
					open={this.state.modalOpen}
					onClose={this.handleCloseModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Supprimer ce compte client
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Voulez-vous vraiment supprimer ce compte client ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleCloseModal}>Annuler</Button>
						<Button onClick={this.handleDelete} autoFocus>
							Valider
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}

	static defaultProps = {
		customerLastName: '',
		customerFirstName: '',
		customerAmount: '',
		accountDetails: {
			balance: 0,
			nbVisite: 0,
			info1: 0,
			info2: 0,
		},
		companies: [],
		customerInfos: {
			customerCompanyId: '',
			customerCompanyName: '',
			email: '',
			phone: '',
		},
	};
}

CustomerAccountDetails.propTypes = {};

function mapStateToProps(state) {
	const user = state.auth.user
	let isSuite = false;
	if (user && user.groups && Array.isArray(user.groups)) {
		isSuite = user.groups.some(
			group => group.name === 'laddition-suite'
		);
		
	}
	return {
		auth: state.auth,
		i18n: state.i18n,
		notifications: state.entities.notifications,
		openDrawer: state.variables.openDrawer,
		isSuite
	};
}

export default connect(mapStateToProps, { addFlashMessage })(CustomerAccountDetails);
