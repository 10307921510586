import React, { useState, useEffect } from 'react';
import CellEditable from '../containers/CellEditable';
import TextField from '@material-ui/core/TextField';

const variables = [
	/*{ key: 'account_vat_company', label: 'Société' },
	{
		key: 'account_vat_site',
		label: 'Code Etablissement',
		desc: '(max 3 caractères)',
		maxLength: 3,
	},*/
	{ key: 'account_vat_validdate', label: 'FEC | ValidDate', desc: 'Par défaut aujourd\'hui'},
	{ key: 'account_vat_journal', label: 'Code du journal' },
	{ key: 'account_vat_journallib', label: 'Libellé du journal' },
	//{ key: 'account_vat_typepiece', label: 'Type de pièce' },
	//{ key: 'account_vat_devise', label: 'Devise' },
	{ key: 'account_vat_customer_account', label: 'Compte client (par défaut)' },
	{ key: 'account_vat_achats', label: 'Compte Achats (par défaut)' },
	{ key: 'account_vat_ventes', label: 'Compte Ventes hors caisse (par défaut)' },
	{ key: 'account_vat_tips', label: 'Compte pourboires' },
	{ key: 'account_vat_regulation_account', label: 'Compte de régulation', desc: '(notifier les écarts de règlement en fin de service)'},
];

export default function GlobalSettings({ vatKeys, onSave }) {
	const [settings, setSettings] = useState({});
	useEffect(() => {
		setSettings(vatKeys);
	}, [vatKeys]);

	const onChange = (key, value) => {
		setSettings({ ...settings, [key]: value });
	};
	return (
		<div className="vat-settings-keys-configuration">
			{variables.map((v, i) => {
				return (
					<div className="keys-container" key={i}>
						<span>
							{v.label} {v.desc || ''} :{' '}
						</span>
						{v.type == 'select' ? (
							<select onChange={e => onChange(v.key, e.target.value)}>
								{v.options.map(o => (
									<option value={o.value}>{o.label}</option>
								))}
							</select>
						) : (
							<TextField
								value={settings[v.key]}
								label={v.label}
								onChange={e => onChange(v.key, e.target.value)}
								variant="outlined"
								inputProps={{ maxlength: v.maxLength }}
							/>
						)}
					</div>
				);
			})}
			<div className="vat-settings-btn">
				<button
					className={`btn-vat-settings`}
					onClick={() => {
						onSave(settings);
					}}>
					Enregistrer
				</button>
			</div>
		</div>
	);
}
